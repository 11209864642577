import React from 'react';
//@ts-ignore
import { useSoftRiseShadowStyles } from '@mui-treasury/styles/shadow/softRise';
import { Card, CardProps } from '@material-ui/core';

export const SoftRiseShadowCard: React.FC<
  Omit<CardProps, 'variant'> & { inactive?: boolean }
> = ({ inactive, ...cardProps }) => {
  const styles = useSoftRiseShadowStyles({ inactive });
  return <Card {...cardProps} classes={styles} />;
};
