import React from "react";
import {
  createStyles,
  makeStyles,
  Typography,
  TypographyProps,
} from "@material-ui/core";

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      fontWeight: theme.typography.fontWeightLight,
      margin: theme.spacing(5, 0, 2),
      fontSize: "2.2rem",
      textTransform: "uppercase",
    },
  })
);

type Props = Pick<TypographyProps, "align">;

export const PageHeader: React.FC<Props> = props => {
  const classes = useStyles();
  return (
    <Typography
      className={classes.root}
      variant="h4"
      align={props.align ?? "left"}
    >
      {props.children}
    </Typography>
  );
};
