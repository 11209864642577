import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Container,
  createStyles,
  makeStyles,
  Typography
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import MDEditor from '@uiw/react-md-editor'
import axios from 'axios'
import clsx from 'clsx'
import React from 'react'
import { Helmet } from 'react-helmet'
import useSWR from 'swr'
import { PageHeader } from '../components/page-header'
import { SoftRiseShadowCard } from '../components/soft-rise-shadow-card'
import { FAQ, PaginatedFAQs, ServerResponse } from '../types'

// import { useFAQPages } from '../features/faq/useFAQPages'
// import useSendGTMDataLayer from '../hooks/useSendGTMDataLayer'
// import { FAQ } from '../xr-platform-api'
// import { PageHeader } from './PageHeader'
// import { SoftRiseShadowCard } from './SoftRiseShadowCard'



const PER_PAGE = 150

const useStyles = makeStyles(theme =>
  createStyles({
    details: {
      flexDirection: 'column',
      padding: theme.spacing(2, 3),
      '& .wmde-markdown': {
        backgroundColor: theme.palette.background.paper,
        color: "black"
      },
      '& .wmde-markdown img': {
        backgroundColor: 'transparent'
      }
    },
    list: {
      padding: 0,
      marginBottom: theme.spacing(4)
    },
    nested: {
      paddingLeft: theme.spacing(4)
    },
    roundedTop: {
      borderTopLeftRadius: theme.shape.borderRadius,
      borderTopRightRadius: theme.shape.borderRadius,
      // '&:before': {
      //   display: 'none'
      // }
    },
    roundedBottom: {
      borderBottomLeftRadius: theme.shape.borderRadius,
      borderBottomRightRadius: theme.shape.borderRadius,
      marginBottom: theme.spacing(2)
    },
    loading: {
      height: 350,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    container: {
      paddingBottom: theme.spacing(3)
    },
  })
)

const FAQPage = () => {

  const styles = useStyles()

  const { data, error, mutate, isValidating } = useSWR(
    `/api/v1/faq_pages?per_page=${PER_PAGE}`,
    (key) =>
      axios.get<ServerResponse & PaginatedFAQs>(key).then(res => res.data),
    {revalidateOnFocus: false}
  );

  const pages = data?.faq_pages?.reduce<{ [s: string]: FAQ[] }>(
    (accum, curr) => {
      const key = curr.category ?? 'Uncategorized'
      if (accum[key] === undefined) return accum
      accum[key].push(curr)
      return accum
    },
    {
      General: [],
      'Tutorial Videos': [],
      'AR Viewing Options': [],
      'Creating a Presentation (on Desktop)': [],
      'Viewing the Presentation (on Mobile)': [],
      Subscriptions: [],
      'Team Plan Features': [],
      Contact: []
    }
  ) ?? []


  return (
    <Container maxWidth='lg'>
      {/* @ts-ignore */}
      <Helmet
        title="Post Reality - FAQs"
        meta={[
          {
            name: "description",
            content:
              "Post Reality frequently asked questions and tutorials",
          },
          {
            name: "keywords",
            content:
              "post reality, faq, tutorial",
          },
        ]}
      />
      <PageHeader align='center'>Frequently Asked Questions</PageHeader>
      {!data && !error  ? <div className={styles.loading}>
          <CircularProgress size="4rem" color="primary" />
        </div>  : Object.entries(pages)
        .filter(entry => entry[1].length > 0)
        .map(entry => (
          <div className={styles.container}>
            <Typography variant='h6' paragraph>
              {entry[0]}
            </Typography>
            {entry[1].map((faq, i, arr) => (
              <Accordion
                className={clsx({
                  [styles.roundedTop]: i === 0,
                  [styles.roundedBottom]: i === arr.length - 1
                })}
                key={faq.id}
                component={props => <SoftRiseShadowCard {...props} inactive />}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`${faq.id}-panel-content`}
                  id={`${faq.id}-panel-header`}
                >
                  <Typography variant='subtitle1'>
                    <b>{faq.name ?? ''}</b>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={styles.details}>
                  <MDEditor.Markdown
                    source={faq.text}
                    style={{ fontFamily: 'inherit' }}
                  />
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        ))}
      {/* <Typography variant="h6" paragraph>
        General
      </Typography>
      {faq_pages
        .filter(faq => faq.category === 'General')
        .map((faq, i, arr) => (
          <Accordion
            className={clsx({
              [styles.roundedTop]: i === 0,
              [styles.roundedBottom]: i === arr.length - 1,
            })}
            key={faq.id}
            component={props => <SoftRiseShadowCard {...props} inactive />}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`${faq.id}-panel-content`}
              id={`${faq.id}-panel-header`}
            >
              <Typography variant="subtitle1">
                <b>{faq.name ?? ''}</b>
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={styles.details}>
              <MDEditor.Markdown
                source={faq.text}
                style={{ fontFamily: 'inherit' }}
              />
            </AccordionDetails>
          </Accordion>
        ))}
      <Typography variant="h6" paragraph>
        Studio (Create)
      </Typography>
      {faq_pages
        .filter(faq => faq.category === 'Studio (Create)')
        .map((faq, i, arr) => (
          <Accordion
            className={clsx({
              [styles.roundedTop]: i === 0,
              [styles.roundedBottom]: i === arr.length - 1,
            })}
            key={faq.id}
            component={props => <SoftRiseShadowCard {...props} inactive />}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="subtitle1">
                <b>{faq.name ?? ''}</b>
              </Typography>
            </AccordionSummary>

            <AccordionDetails className={styles.details}>
              <MDEditor.Markdown
                source={faq.text}
                // components={{
                //   p: ({ children }) => (
                //     <Typography component="p" variant="body1">
                //       {children}
                //     </Typography>
                //   ),
                // }}
                style={{ fontFamily: 'inherit' }}
              />
            </AccordionDetails>
          </Accordion>
        ))}
      <Typography variant="h6" paragraph>
        App (Viewer)
      </Typography>
      {faq_pages
        .filter(faq => faq.category === 'App (Viewer)')
        .map((faq, i, arr) => (
          <Accordion
            className={clsx({
              [styles.roundedTop]: i === 0,
              [styles.roundedBottom]: i === arr.length - 1,
            })}
            key={faq.id}
            component={props => <SoftRiseShadowCard {...props} inactive />}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="subtitle1">
                <b>{faq.name ?? ''}</b>
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={styles.details}>
              <MDEditor.Markdown
                source={faq.text}
                // components={{
                //   p: ({ children }) => (
                //     <Typography component="p" variant="body1">
                //       {children}
                //     </Typography>
                //   ),
                // }}
                style={{ fontFamily: 'inherit' }}
              />
            </AccordionDetails>
          </Accordion>
        ))}
      <Typography variant="h6" paragraph>
        Others
      </Typography>
      {faq_pages
        .filter(faq => faq.category === 'Others')
        .map((faq, i, arr) => (
          <Accordion
            className={clsx({
              [styles.roundedTop]: i === 0,
              [styles.roundedBottom]: i === arr.length - 1,
            })}
            key={faq.id}
            component={props => <SoftRiseShadowCard {...props} inactive />}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="subtitle1">
                <b>{faq.name ?? ''}</b>
              </Typography>
            </AccordionSummary>

            <AccordionDetails className={styles.details}>
              <MDEditor.Markdown
                source={faq.text}
                // components={{
                //   p: ({ children }) => (
                //     <Typography component="p" variant="body1">
                //       {children}
                //     </Typography>
                //   ),
                // }}
                style={{ fontFamily: 'inherit' }}
              />
            </AccordionDetails>
          </Accordion>
        ))} */}

      {/* {faq_pages.length > 0 && (
        <MDEditor.Markdown
          source={faq_pages[0].text}
          // components={{}}
          style={{ marginTop: 16, fontFamily: "Roboto" }}
        />
      )} */}
      {/* <PageHeader>Frequently Asked Questions</PageHeader>
      <Accordion
        component={props => <SoftRiseShadowCard {...props} inactive />}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>What do I need to get started?</Typography>
        </AccordionSummary>
        <AccordionDetails className={styles.details}>
          <List className={styles.list}>
            <ListItem alignItems="flex-start">
              <ListItemText
                primary="An image file (.jpg and less than 5MB)"
                primaryTypographyProps={{ variant: "body2" }}
              />
            </ListItem>
            <List component="div" disablePadding>
              <ListItem alignItems="flex-start" className={styles.nested}>
                <ListItemText
                  primary="The image should be an original file (not a photo of another photo)"
                  primaryTypographyProps={{ variant: "body2" }}
                />
              </ListItem>
            </List>
            <ListItem alignItems="flex-start">
              <ListItemText
                primary="Chrome or Firefox web browser to build your AR Poster."
                primaryTypographyProps={{ variant: "body2" }}
              />
            </ListItem>
            <ListItem alignItems="flex-start">
              <ListItemText
                primary={
                  <span>
                    An AR-supported phone or tablet to view presentations
                    (AR-supported{" "}
                    <Link
                      href="https://www.arcade.ltd/is-my-device-ar-compatible/"
                      target="_blank"
                    >
                      device list
                    </Link>
                    )
                  </span>
                }
                primaryTypographyProps={{ variant: "body2" }}
              />
            </ListItem>
            <List component="div" disablePadding>
              <ListItem alignItems="flex-start" className={styles.nested}>
                <ListItemText
                  primary="To view AR presentations in your environment, markers (background images) must be printed and larger than 6 inches x 6 inches."
                  primaryTypographyProps={{ variant: "body2" }}
                />
              </ListItem>
            </List>
          </List>
        </AccordionDetails>
      </Accordion>

      <Accordion
        component={props => <SoftRiseShadowCard {...props} inactive />}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>
            How do I create an Augmented Reality (AR) presentation?
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={styles.details}>
          <Typography variant="subtitle2" paragraph>
            Step 1: Select the “Create Presentation” button in the “My
            Presentations” tab and upload a marker (background image).
          </Typography>
          <List className={styles.list}>
            <ListItem alignItems="flex-start">
              <ListItemText
                primary="If a phone/tablet recognizes a presentation slowly or the viewing performance is undesirable, add detail and geometric complexity to the background image."
                primaryTypographyProps={{ variant: "body2" }}
              />
            </ListItem>
            <ListItem alignItems="flex-start">
              <ListItemText
                primary="Click the “Swap” button on the top menu when editing the presentation to exchange the background image easily."
                primaryTypographyProps={{ variant: "body2" }}
              />
            </ListItem>
          </List>

          <Typography variant="subtitle2" paragraph>
            Step 2: Build your AR presentation.
          </Typography>
          <List className={styles.list}>
            <ListItem alignItems="flex-start">
              <ListItemText
                primary="Add media assets (images, videos, 3D models, and audio files) to display in AR"
                primaryTypographyProps={{ variant: "body2" }}
              />
            </ListItem>
            <ListItem alignItems="flex-start">
              <ListItemText
                primary="Everyone:"
                primaryTypographyProps={{
                  variant: "body2",
                  style: { textDecoration: "underline" },
                }}
              />
            </ListItem>
            <List className={styles.nested}>
              <ListItem alignItems="flex-start">
                <ListItemText
                  primary="Click the “Import” box to launch the PostReality Store."
                  primaryTypographyProps={{ variant: "body2" }}
                />
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemText
                  primary="Browse free and paid assets."
                  primaryTypographyProps={{ variant: "body2" }}
                />
              </ListItem>
              <List className={styles.nested}>
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary="Search assets by tags on the top menu bar’s right side."
                    primaryTypographyProps={{ variant: "body2" }}
                  />
                </ListItem>
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary="Click the arrow on the top menu bar to access previously purchased assets."
                    primaryTypographyProps={{ variant: "body2" }}
                  />
                </ListItem>
              </List>
            </List>
            <ListItem alignItems="flex-start">
              <ListItemText
                primary="Personal/Team Subscribers"
                primaryTypographyProps={{
                  variant: "body2",
                  style: { textDecoration: "underline" },
                }}
              />
            </ListItem>
            <List className={styles.nested}>
              <ListItem alignItems="flex-start">
                <ListItemText
                  primary="Upload personal media assets by clicking the “+” button by the desired media asset type."
                  primaryTypographyProps={{
                    variant: "body2",
                  }}
                />
              </ListItem>
              <List className={styles.nested}>
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary="Make sure each file is the correct type and within its size limit."
                    primaryTypographyProps={{
                      variant: "body2",
                    }}
                  />
                </ListItem>
              </List>
            </List>
            <ListItem alignItems="flex-start">
              <ListItemText
                primary="Highlight the move, rotate, or scale button on the top menu to arrange your media assets."
                primaryTypographyProps={{ variant: "body2" }}
              />
            </ListItem>
            <List className={styles.nested}>
              <ListItem alignItems="flex-start">
                <ListItemText
                  primary="To create a call-to-action (a hyperlinked button), select an image and click the image icon by the file’s name on the left menu bar."
                  primaryTypographyProps={{
                    variant: "body2",
                  }}
                />
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemText
                  primary="Select “Link” or “Email” from the dropdown and type in a URL or Email address, respectively."
                  primaryTypographyProps={{
                    variant: "body2",
                  }}
                />
              </ListItem>
            </List>
          </List>

          <Typography variant="subtitle2" paragraph>
            Step 3: View your poster in augmented reality.
          </Typography>
          <List className={styles.list}>
            <ListItem alignItems="flex-start">
              <ListItemText
                primary="Use the PostReality App on a phone/tablet to view a presentation."
                primaryTypographyProps={{
                  variant: "body2",
                }}
              />
            </ListItem>
            <ListItem alignItems="flex-start">
              <ListItemText
                primary={
                  <Link
                    href="https://apps.apple.com/us/app/postreality-viewer/id1572303070"
                    target="_blank"
                  >
                    Click here to download iOS app.
                  </Link>
                }
                primaryTypographyProps={{
                  variant: "body2",
                }}
              />
            </ListItem>
            <ListItem alignItems="flex-start">
              <ListItemText
                primary={
                  <Link
                    href="https://play.google.com/store/apps/details?id=com.postreality.viewer"
                    target="_blank"
                  >
                    Click here to download Android app.
                  </Link>
                }
                primaryTypographyProps={{
                  variant: "body2",
                }}
              />
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>

      <Accordion
        component={props => <SoftRiseShadowCard {...props} inactive />}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography>
            What is the QR code, and why do I need to use it?
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={styles.details}>
          <List className={styles.list}>
            <ListItem alignItems="flex-start">
              <ListItemText
                primary="A hurdle in Augmented Reality (AR) is informing viewers that an interactive presentation exists."
                primaryTypographyProps={{ variant: "body2" }}
              />
            </ListItem>
            <ListItem alignItems="flex-start">
              <ListItemText
                primary="QR codes serve as a “call-to-action,” prompting viewers to point their phones at a marker (background image). The QR code will either open the presentation in the PostReality app or take a viewer to an app store."
                primaryTypographyProps={{ variant: "body2" }}
              />
            </ListItem>
            <ListItem alignItems="flex-start">
              <ListItemText
                primary="Each QR code is unique to that presentation and allows for faster detection."
                primaryTypographyProps={{ variant: "body2" }}
              />
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion> */}
    </Container>
  )
}

export default FAQPage
